import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {MidSeason} from "views/components/mid_season/mid_season.component";
import {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {Bindings} from "data/constants/bindings";
import {Session} from "views/components/session/session.component";
import {Header} from "views/components/header/header.component";
import {RootRoutes, SecretGateRoutes} from "routes";
import {Footer} from "views/components/footer/footer.component";

export const RouteGate: React.FC = observer(() => {
	const {isSecretPassed} = useViewController<ISecretGateController>(
		Bindings.SecretGateController
	);
	return (
		<React.Fragment>
			{isSecretPassed ? (
				<MidSeason>
					<Session>
						<Header />
						<RootRoutes />
						<Footer />
					</Session>
				</MidSeason>
			) : (
				<React.Fragment>
					<SecretGateRoutes />
					<Footer />
				</React.Fragment>
			)}
		</React.Fragment>
	);
});
