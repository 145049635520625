import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IMidSeasonController extends ViewController {
	get i18n(): ILocalizationStore;

	get isMidSeason(): boolean;
}

@injectable()
export class MidSeasonController implements IMidSeasonController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	get isMidSeason() {
		return this._sponsorStore.isMidSeason;
	}
}
