import styled from "@emotion/styled";
import logos from "assets/img/landing/logo.png";
import finalLogo from "assets/img/common/final_logo.png";
import welcomeBg from "assets/img/coming_soon/welcome-bg.jpg";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {Footer} from "views/components/footer/footer.component";
import {IMidSeasonController} from "views/components/mid_season/mid_season.controller";

const Wrapper = styled.div`
	display: flex;
	text-align: center;
	min-height: calc(100vh - 72px);
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: url(${welcomeBg});
	background-size: cover;
`;
const Logos = styled.img`
	max-width: 280px;
	width: 100%;
	margin: 0 auto 20px auto;
	user-select: none;
`;
const Content = styled.div`
	background: var(--text-white);
	max-width: 335px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 16px 12px 12px 12px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	img {
		height: 70px;
		width: auto;
	}

	p {
		color: var(--text-default);
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 125%;

		b {
			font-weight: bold;
		}
	}
`;

export const MidSeason: React.FC<PropsWithChildren> = observer(({children}) => {
	const {i18n, isMidSeason} = useViewController<IMidSeasonController>(
		Bindings.MidSeasonController
	);

	const copy = i18n.t(
		"page.mid_season.copy",
		"<b>Phase 2</b> will commence on January <b>15th</b>.<br/> Return to play and stand a chance to <b>win tickets to the 2025 Stanley Cup® Final</b> and other prizes."
	);

	if (!isMidSeason) {
		return <React.Fragment>{children}</React.Fragment>;
	}

	return (
		<React.Fragment>
			<Wrapper>
				<Logos src={logos} />
				<Content>
					<img src={finalLogo} alt="Final Stanley Cup" />
					<p
						// Disabled as Loco is safe source of html
						// eslint-disable-next-line
						dangerouslySetInnerHTML={{__html: copy}}
					/>
				</Content>
			</Wrapper>
			<Footer />
		</React.Fragment>
	);
});
