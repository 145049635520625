import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {
	DoublePointsBannerController,
	IDoublePointsBannerController,
} from "views/components/double_points_banner/double_points_banner.controller";
import {
	ILeaderboardFiltersController,
	LeaderboardFiltersController,
} from "views/components/leaderboard_filters/leaderboard_filters.controller";
import {
	IMidSeasonController,
	MidSeasonController,
} from "views/components/mid_season/mid_season.controller";
import {
	IModalPreDoublePointsController,
	ModalPreDoublePointsController,
} from "views/components/modals/modal_pre_double_points/modal_pre_double_points.controller";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore, PlayersStore} from "data/stores/players/players.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	CreateLeagueController,
	type ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {IMenuController, MenuController} from "views/components/menu/menu.controller";
import {GameBarController, IGameBarController} from "views/components/game_bar/game_bar.controller";
import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/round_selector/round_selector.controller";
import {IPicksController, PicksController} from "views/pages/picks/picks.controller";
import {
	IMatchCardController,
	MatchCardController,
} from "views/components/match_card/match_card.controller";
import {
	IPickButtonController,
	PickButtonController,
} from "views/components/pick_button/pick_button.controller";
import {IPredictionsStore, PredictionsStore} from "data/stores/predictions/predictions.store";
import {
	IPredictionsApiProvider,
	PredictionsApiProvider,
} from "data/providers/api/predictions.api.provider";
import {
	ActionBannerController,
	IActionBannerController,
} from "views/components/action_banner/action_banner.controller";
import {
	IMatchStatusController,
	MatchStatusController,
} from "views/components/match_status/match_status.controller";
import {
	IModalLeaveController,
	ModalLeaveController,
} from "views/components/modals/modal_leave/modal_leave.controller";
import {
	IModalLeaveNavigateController,
	ModalLeaveNavigateController,
} from "views/components/modals/modal_leave_navigate/modal_leave_navigate.controller";
import {
	IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {GameBarStore, IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {
	ILeaderboardApiProvider,
	LeaderboardApiProvider,
} from "data/providers/api/leaderboard.api.provider";
import {ILeaderboardStore, LeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {
	ILeaderboardController,
	LeaderboardController,
} from "views/pages/leaderboard/leaderboard.controller";
import {
	IModalPicksConfirmController,
	ModalPicksConfirmController,
} from "views/components/modals/modal_picks_confirm/modal_picks_confirm.controller";
import {type ISponsorStore, SponsorStore} from "data/stores/sponsor/sponsor.store";
import {type ISponsorProvider, SponsorProvider} from "data/providers/api/sponsor.provider";
import {ISharingController, SharingController} from "views/components/sharing/sharing.controller";
import {
	IScrollButtonController,
	ScrollButtonController,
} from "views/components/scroll_button/scroll_button.controller";
import {
	IModalLeaveAccountController,
	ModalLeaveAccountController,
} from "views/components/modals/modal_leave_account/modal_leave_account.controller";
import {
	IModalGameLogicChangesController,
	ModalGameLogicChangesController,
} from "views/components/modals/modal_game_logic_changes/modal_game_logic_changes.controller";
import {
	IModalReturnedUserController,
	ModalReturnedUserController,
} from "views/components/modals/modal_returned_user/modal_returned_user.controller";
import {
	IModalRankingsInfoController,
	ModalRankingsInfoController,
} from "views/components/modals/modal_rankings_info/modal_rankings_info.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaderboardApiProvider>(Bindings.LeaderboardApiProvider).to(LeaderboardApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IPredictionsApiProvider>(Bindings.PredictionsApiProvider).to(PredictionsApiProvider);
	bind<ISponsorProvider>(Bindings.SponsorProvider).to(SponsorProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IPredictionsStore>(Bindings.PredictionsStore).to(PredictionsStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<ILeaderboardStore>(Bindings.LeaderboardStore).to(LeaderboardStore).inSingletonScope();
	bind<ISponsorStore>(Bindings.SponsorStore).to(SponsorStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController)
		.to(SecretGateController)
		.inSingletonScope();
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<IPicksController>(Bindings.PicksController).to(PicksController);
	bind<IMatchCardController>(Bindings.MatchCardController).to(MatchCardController);
	bind<IPickButtonController>(Bindings.PickButtonController).to(PickButtonController);
	bind<IActionBannerController>(Bindings.ActionBannerController).to(ActionBannerController);
	bind<IMatchStatusController>(Bindings.MatchStatusController).to(MatchStatusController);
	bind<IModalLeaveController>(Bindings.ModalLeaveController).to(ModalLeaveController);
	bind<IModalLeaveNavigateController>(Bindings.ModalLeaveNavigateController).to(
		ModalLeaveNavigateController
	);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<IModalPicksConfirmController>(Bindings.ModalPicksConfirmController).to(
		ModalPicksConfirmController
	);
	bind<ISharingController>(Bindings.SharingController).to(SharingController);
	bind<IScrollButtonController>(Bindings.ScrollButtonController).to(ScrollButtonController);
	bind<IModalLeaveAccountController>(Bindings.ModalLeaveAccountController).to(
		ModalLeaveAccountController
	);
	bind<IModalGameLogicChangesController>(Bindings.ModalGameLogicChangesController).to(
		ModalGameLogicChangesController
	);
	bind<IModalReturnedUserController>(Bindings.ModalReturnedUserController).to(
		ModalReturnedUserController
	);
	bind<IModalRankingsInfoController>(Bindings.ModalRankingsInfoController).to(
		ModalRankingsInfoController
	);
	bind<IModalPreDoublePointsController>(Bindings.ModalPreDoublePointsController).to(
		ModalPreDoublePointsController
	);
	bind<IDoublePointsBannerController>(Bindings.DoublePointsBannerController).to(
		DoublePointsBannerController
	);
	bind<ILeaderboardFiltersController>(Bindings.LeaderboardFiltersController).to(
		LeaderboardFiltersController
	);
	bind<IMidSeasonController>(Bindings.MidSeasonController).to(MidSeasonController);
});
